import React, {
	createContext,
	useContext,
	useEffect,
	useState,
	useRef,
} from 'react';
import { useApolloClient, gql } from '@apollo/client';

import { scrollToElement } from 'libs/content';

const FiberOrderContext = createContext(null);
export const useFiberOrderContext = () => useContext(FiberOrderContext);

export const fiberChosenProductKey = 'nte-fiber-v2-chosen-product';
export const fiberProductsKey = 'nte-fiber-v2-products';
export const fiberChosenAddOnsKey = 'nte-fiber-v2-chosen-addons';
export const fiberAddOnsKey = 'nte-fiber-v2-addons';
export const fiberAddressKey = 'nte-fiber-v2-address';
export const fiberOrderKey = 'nte-fiber-v2-order';
export const fiberDeliverResultsKey = 'nte-fiber-v2-deliver-results';
export const fiberLeadKey = 'nte-fiber-v2-lead';

export default function FiberProviderV2({
	location,
	urlParams,
	children,
	step = 1,
}) {
	const [view, setView] = useState('Fiberbredbånd');

	const [products, _setProducts] = useState([]);
	const [productTypesResult, setProductTypesResult] = useState('');
	const [chosenProduct, _setChosenProduct] = useState({});

	const [addOns, _setAddOns] = useState([]);
	const [chosenAddOns, _setChosenAddOns] = useState([]);

	const [address, _setAddress] = useState({});
	const [deliverResults, _setDeliverResults] = useState({
		searchKeyword: '',
		checked: false,
	});
	const termsLoadingRef = useRef(false);

	const [order, _setOrder] = useState({});
	const [orderSubmitted, setOrderSubmitted] = useState(false);

	const [lead, _setLead] = useState({});
	const [leadSubmitted, setLeadSubmitted] = useState(false);

	const [loading, setLoading] = useState(true);
	const [errors, setErrors] = useState([]);

	const client = useApolloClient();

	/**
	 * Merge the products from the deliverResults and Contentful based on matching name with tariff.
	 * @type {Array} mergedProducts - An array of merged products.
	 * @returns {void}
	 **/
	useEffect(() => {
		// Clear the product states
		if (products?.length > 0) setProducts([]);
		if (addOns?.length > 0) setAddOns([]);

		// If there are no products, exit the function.
		if (!deliverResults?.products?.length > 0) {
			return;
		}

		// Otherwise set the products state
		setProducts(deliverResults?.products);

		// Check each product type products and add it to the product types state.
		const productTypes = ['Fiberbredbånd', 'Produktpakke'];
		productTypes.forEach(type => {
			if (
				deliverResults?.products?.some(p => p?.type === type) &&
				!productTypesResult.includes(type)
			) {
				setProductTypesResult(prev => [...prev, type]);
			}
		});

		// If there are options, set the add-ons state based on chosen product-variant
		if (deliverResults?.options?.length > 0) {
			if (!chosenProduct?.variant?.length > 0) return;
			setAddOns(
				deliverResults?.options?.filter(o =>
					o?.productType?.includes(chosenProduct?.variant[0])
				)
			);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deliverResults?.products?.length, deliverResults?.options?.length]);

	/**
	 * Update the states based on the values stored in sessionStorage on mount.
	 * @returns {void}
	 **/
	const getFromSessionStorage = (key, setter) => {
		const storedData = sessionStorage.getItem(key);
		if (storedData) {
			setter(JSON.parse(storedData));
		}
	};

	useEffect(() => {
		setLoading(true);

		getFromSessionStorage(fiberAddressKey, _setAddress); // Address
		getFromSessionStorage(fiberOrderKey, _setOrder); // Order
		getFromSessionStorage(fiberDeliverResultsKey, _setDeliverResults); // Deliver results
		getFromSessionStorage(fiberProductsKey, _setProducts); // Products that can be selected
		getFromSessionStorage(fiberAddOnsKey, _setAddOns); // Add-ons that can be selected
		getFromSessionStorage(fiberChosenProductKey, _setChosenProduct); // Chosen product
		getFromSessionStorage(fiberChosenAddOnsKey, _setChosenAddOns); // Chosen add ons
		getFromSessionStorage(fiberLeadKey, _setLead); // Lead

		setLoading(false);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Save address to session storage and state.
	 * @param {object} value - The address to save.
	 * @returns {void}
	 **/
	const setAddress = value => {
		const updatedAddress = { ...address, ...value };
		sessionStorage.setItem(fiberAddressKey, JSON.stringify(updatedAddress));
		_setAddress(updatedAddress);

		// Update the stored JSON value in sessionStorage
		const storedJson = JSON.parse(sessionStorage.getItem(fiberAddressKey));
		const updatedJson = { ...storedJson, ...value };
		sessionStorage.setItem(fiberAddressKey, JSON.stringify(updatedJson));
	};

	/**
	 * Clear address and session storage.
	 * @returns {void}
	 */
	const clearAddress = () => {
		sessionStorage.removeItem(fiberAddressKey);
		_setAddress({});
	};

	const setDeliverResults = value => {
		if (!value) {
			sessionStorage.removeItem(fiberDeliverResultsKey);
			_setDeliverResults({
				searchKeyword: '',
				checked: false,
			});
			return;
		}

		_setDeliverResults(prev => {
			sessionStorage.setItem(
				fiberDeliverResultsKey,
				JSON.stringify({ ...prev, ...value })
			);

			return { ...prev, ...value };
		});
	};

	/**
	 * Save order data to session storage and state.
	 * @param {object} value - The data to save.
	 * @returns {void}
	 **/
	const setOrder = value => {
		const updatedOrder = { ...order, ...value };
		sessionStorage.setItem(fiberOrderKey, JSON.stringify(updatedOrder));
		_setOrder(updatedOrder);

		// Update the stored JSON value in sessionStorage
		const storedJson = JSON.parse(sessionStorage.getItem(fiberOrderKey));
		const updatedJson = { ...storedJson, ...value };
		sessionStorage.setItem(fiberOrderKey, JSON.stringify(updatedJson));
	};

	/**
	 * Save lead data to session storage and state.
	 * @param {object} value - The data to save.
	 * @returns {void}
	 **/
	const setLead = value => {
		const updatedLead = { ...lead, ...value };
		sessionStorage.setItem(fiberLeadKey, JSON.stringify(updatedLead));
		_setLead(updatedLead);

		// Update the stored JSON value in sessionStorage
		const storedJson = JSON.parse(sessionStorage.getItem(fiberLeadKey));
		const updatedJson = { ...storedJson, ...value };
		sessionStorage.setItem(fiberLeadKey, JSON.stringify(updatedJson));
	};

	/**
	 * Save products to session storage and state.
	 * @param {Array} products - The products to save.
	 * @returns {void}
	 **/
	const setProducts = products => {
		if (!products?.length > 0) {
			_setProducts([]);
			return;
		}
		sessionStorage.setItem(fiberProductsKey, JSON.stringify(products));
		_setProducts(products);
	};

	/**
	 * Save add-on products to session storage and state.
	 * @param {Array} options - The addon-options to save.
	 * @returns {void}
	 **/
	const setAddOns = options => {
		if (!options?.length > 0) {
			_setAddOns([]);
			return;
		}
		sessionStorage.setItem(fiberAddOnsKey, JSON.stringify(options));
		_setAddOns(options);
	};

	/**
	 * Save chosen product to state and session storage.
	 * @param {object} value - The product to add.
	 * @returns {void}
	 **/
	const setChosenProduct = prod => {
		if (!prod?.slug) return;

		// Save the chosen product to sessionStorage and state
		sessionStorage.setItem(fiberChosenProductKey, JSON.stringify(prod));
		_setChosenProduct(prod);
	};

	/**
	 * Clear chosen product and session storage.
	 * @returns {void}
	 **/
	const clearChosenProduct = () => {
		sessionStorage.removeItem(fiberChosenProductKey);
		_setChosenProduct({});
	};

	/**
	 * Update the add-ons array.
	 * @param {array} prevAddons - The previous add-ons.
	 * @param {object} choice - The choice to update.
	 * @returns {array} - The updated add-ons array.
	 **/
	const updateAddOns = (prevAddons, choice) => {
		const foundInPrevAddons = prevAddons.find(
			p => p?.slug === choice?.slug
		);

		if (foundInPrevAddons) {
			if (choice?.quantity === 0) return prevAddons;

			return prevAddons.map(a => (a?.slug === choice?.slug ? choice : a));
		} else {
			return [...prevAddons, choice];
		}
	};

	/**
	 * Save chosen add-ons to state and session storage.
	 * @param {object | array} choices - The add-ons to save.
	 * @returns {void}
	 **/
	const setChosenAddOns = choices => {
		if (!choices) return;

		let updatedAddons = chosenAddOns;

		if (Array.isArray(choices)) {
			if (!choices?.length > 0) return;
			choices.forEach(choice => {
				updatedAddons = updateAddOns(updatedAddons, choice);
			});
		} else if (typeof choices === 'object') {
			updatedAddons = updateAddOns(updatedAddons, choices);
		}

		_setChosenAddOns(updatedAddons);
		sessionStorage.setItem(
			fiberChosenAddOnsKey,
			JSON.stringify(updatedAddons)
		);
	};

	/**
	 * Clear chosen add-on and session storage.
	 * @returns {void}
	 **/
	const clearChosenAddOns = () => {
		sessionStorage.removeItem(fiberChosenAddOnsKey);
		_setChosenAddOns([]);

		//Remove the object "tilleggsprodukter" from the order object
		const updatedOrder = { ...order };
		delete updatedOrder.tilleggsprodukter;
		sessionStorage.setItem(fiberOrderKey, JSON.stringify(updatedOrder));
		_setOrder(updatedOrder);
	};

	/**
	 * Remove all quantities of add-on from array state and sessionstorage
	 * @returns {void}
	 **/
	const removeFromChosenAddons = addonToRemove => {
		if (!chosenAddOns?.length > 0 || !addonToRemove) return;

		const updatedAddOns = chosenAddOns?.filter(
			a => a?.slug !== addonToRemove?.slug
		);
		_setChosenAddOns(updatedAddOns);
		sessionStorage.setItem(
			fiberChosenAddOnsKey,
			JSON.stringify(updatedAddOns)
		);
	};

	async function fetchTerms() {
		try {
			termsLoadingRef.current = true;
			const res = await client.query({
				query: gql`
					query getDeliveryTerms(
						$servicepoint_id: String
						$bundle_id: Int
						$landing_page: String!
					) {
						getDeliveryTerms(
							servicepoint_id: $servicepoint_id
							bundle_id: $bundle_id
							landing_page: $landing_page
						) {
							terms {
								text_id
								priority
								views
								title
								paragraphs {
									text_id
									text
									priority
									content_type
								}
							}
							thankYouText
						}
					}
				`,
				variables: {
					servicepoint_id: deliverResults?.servicepoint_id,
					bundle_id:
						chosenProduct?.api_id &&
						parseInt(chosenProduct?.api_id),
					landing_page: deliverResults?.deliverable?.landing_page,
				},
				skip: !deliverResults?.deliverable?.landing_page,
			});

			if (!res || !res?.data?.getDeliveryTerms?.terms?.length > 0) {
				termsLoadingRef.current = false;
				throw new Error('No delivery terms recieved from API');
			}

			termsLoadingRef.current = false;
			return res?.data?.getDeliveryTerms;
		} catch (e) {
			console.log('Fiber Provider - fetchTerms: ' + e?.message);
		}
	}

	/**
	 * Get all terms for a specific view.
	 * @param {Array} terms - The terms to filter.
	 * @param {string/array} view - The view to filter.
	 * @returns {Array} - The filtered terms.
	 **/
	function getTermsByView(terms = [], view) {
		if (!terms?.length || !view) return;

		if (typeof view !== 'string' && view?.length > 0) {
			return terms?.filter(t => t?.views?.some(v => view.includes(v)));
		} else if (typeof view === 'string') {
			return terms?.filter(t => t?.views?.some(v => v === view));
		}
	}

	return (
		<FiberOrderContext.Provider
			value={{
				mainPageSlug: 'tv-og-internett',
				location,
				step,
				loading,
				address,
				setAddress,
				clearAddress,
				view,
				setView,
				resetView: (view = 'Fiberbredbånd') => {
					setView(view);
					setTimeout(() => {
						scrollToElement(
							document?.getElementById('fiber-delivery-result'),
							300
						);
					}, 150);
				},
				products,
				productTypesResult,
				addOns,
				setAddOns,
				chosenProduct,
				setChosenProduct,
				clearChosenProduct,
				chosenAddOns,
				setChosenAddOns,
				clearChosenAddOns,
				removeFromChosenAddons,

				order,
				setOrder,
				flushFiberContext: () => {
					sessionStorage.removeItem(fiberDeliverResultsKey);
					sessionStorage.removeItem(fiberAddressKey);
					sessionStorage.removeItem(fiberProductsKey);
					sessionStorage.removeItem(fiberChosenProductKey);
					sessionStorage.removeItem(fiberChosenAddOnsKey);
					sessionStorage.removeItem(fiberAddOnsKey);
					sessionStorage.removeItem(fiberOrderKey);
					sessionStorage.removeItem(fiberLeadKey);
					_setDeliverResults({});
					_setAddress({});
					_setProducts([]);
					_setChosenProduct({});
					_setAddOns([]);
					_setChosenAddOns([]);
					_setOrder({});
					_setLead({});
				},
				flushResultContext: () => {
					sessionStorage.removeItem(fiberProductsKey);
					sessionStorage.removeItem(fiberChosenProductKey);
					sessionStorage.removeItem(fiberChosenAddOnsKey);
					sessionStorage.removeItem(fiberAddOnsKey);
					sessionStorage.removeItem(fiberOrderKey);
					_setProducts([]);
					_setChosenProduct({});
					_setAddOns([]);
					_setChosenAddOns([]);
					_setOrder({});
				},
				deliverResults,
				setDeliverResults,
				errors,
				setErrors,
				urlParams,
				isDateInFuture,
				orderSubmitted,
				setOrderSubmitted,
				lead,
				setLead,
				leadSubmitted,
				setLeadSubmitted,
				sortByPrice(arr, priceField, order = 'asc') {
					if (!arr?.length > 0 || !priceField) return;
					if (order === 'desc') {
						return arr.sort(
							(a, b) => b[priceField] - a[priceField]
						);
					}
					return arr.sort((a, b) => a[priceField] - b[priceField]);
				},
				fetchTerms,
				termsLoadingRef,
				getTermsByView,
				splitAddress: address => {
					const [streetAndNumber, zipAndCity] = address.split(',');
					const [zip, ...cityParts] = zipAndCity.trim().split(' ');
					const city = cityParts.join(' ');

					return {
						street: streetAndNumber?.trim(),
						zip: zip,
						city: city,
					};
				},
			}}>
			{children}
		</FiberOrderContext.Provider>
	);
}

/**
 * Check if a date is in the future.
 * @param {string} dateString - The date to check.
 * @returns {boolean} - Indicates if the date is in the future.
 **/
export function isDateInFuture(dateString) {
	const parts = dateString.split('/');
	const day = parseInt(parts[0], 10);
	const month = parseInt(parts[1], 10) - 1; // months are zero-based
	const year = parseInt(parts[2], 10);
	const inputDate = new Date(year, month, day, 0, 0, 0);

	// Get the current date with time set to midnight
	const currentDate = new Date();
	currentDate.setHours(0, 0, 0, 0);

	// Check if the input date is in the future
	if (inputDate >= currentDate) {
		return true;
	} else {
		return false;
	}
}
