import React, { useState, useRef, useEffect, useMemo, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { useApolloClient, gql } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { faCircleXmark } from '@fortawesome/pro-regular-svg-icons/faCircleXmark';
import debounce from 'lodash/debounce';

import InputField from 'components/forms/InputField';
import BlankButton from 'components/forms/BlankButton';

const AddressInput = styled.div`
	position: relative;
	> div {
		margin-bottom: 0;
	}
`;

const SearchClear = styled.button`
	background-color: transparent;
	border: 0;
	margin-left: auto;
	svg {
		width: 17px;
		height: 26px;
		color: ${p => p.theme.colors.grey700};
	}
	&:hover svg {
		color: ${p => p.theme.colors.blue600};
	}
`;

const DropdownStyle = () => css`
	position: absolute;
	margin: auto;
	top: calc(100% + 10px);
	left: 2px;
	right: 2px;
	width: calc(100% - 4px);
	z-index: 100;
	background: ${p => p.theme.colors.white};
	box-shadow: ${p => p.theme.utils.boxShadow};
	border-radius: ${p => p.theme.utils.borderRadius};
	${p =>
		p.theme.media.smallOnly(css`
			top: calc(100% + 5px);
		`)}
`;

const Dropdown = styled.ul`
	${DropdownStyle}
	list-style: none;
	padding: ${p => `${p.theme.spacing.tablet.xsmall} 0`} !important;
	max-height: 290px;
	overflow: auto;
	button {
		display: block;
		width: 100%;
		padding: ${p =>
			`${p.theme.spacing.desktop.xxsmall} ${p.theme.spacing.desktop.xsmall}`};
		cursor: pointer;
		line-height: 24px;
		font-size: 17px;
		text-align: left;
		background: transparent;
		text-decoration: none;
		color: ${p => p.theme.colors.grey900};
		&:hover {
			background: ${p => p.theme.colors.blue200};
		}
	}
`;

/**
 * Represents a component for searching fiber availability based on the user's address.
 *
 * @param {Object} props - The properties for the SearchAndResults component.
 * @param {Function} props.onSelect - A function for selecting an address.
 * @param {Function} props.onReset - A function for resetting the search.
 * @param {Function} props.onChange - A function for changing the search value.
 * @param {Function} props.setError - A function for setting an error message.
 * @param {boolean} props.loading - Indicates if the search is loading.
 * @param {Function} props.setLoading - A function for setting the loading state.
 * @param {Object} props.inputProps - Additional properties for the input field.
 * @param {string} props.defaultValue - The default value for the search input.
 * @param {Object} ref - The reference for the input field.
 * @returns {ReactNode} - A React element representing the SearchAndResults component.
 */
export default forwardRef(function AddressSearch(
	{
		onSelect = () => {},
		onReset = () => {},
		onChange = () => {},
		setError = () => {},
		onSearchDone = () => {},
		loading = false,
		setLoading = () => {},
		inputProps = {},
		defaultValue = '',
	},
	inputRef
) {
	const [_loading, _setLoading] = useState(false);
	const [value, setValue] = useState(defaultValue);
	const [suggestions, setSuggestions] = useState([]);

	const divRef = useRef(null);
	const defaultValueSet = useRef(false);

	const client = useApolloClient();

	useEffect(() => {
		if (value || loading || defaultValueSet.current) return;

		defaultValueSet.current = true;

		setValue(defaultValue);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultValue]);

	// when click outside div then close div
	useEffect(() => {
		const handleClickOutside = event => {
			if (divRef.current && !divRef.current.contains(event.target)) {
				setSuggestions([]);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [divRef]);

	/**
	 * Reset the search input and clear suggestions.
	 *
	 * @returns {void}
	 */
	const reset = () => {
		setValue('');
		onChange('');
		setSuggestions([]);
		setLoading(false);
		onReset();
	};

	const debouncedSearch = useMemo(
		() =>
			debounce(async val => {
				setError('');

				val = val.trim();

				if (!val?.length > 0) {
					reset();
					return;
				}

				try {
					_setLoading(true);

					const res = await client.query({
						query: gql`
							query getAddresses($keyword: String!) {
								getAddresses(keyword: $keyword) {
									id
									street
									zip
									city
									result
								}
							}
						`,
						variables: {
							keyword: val,
						},
						skip: !val,
					});

					setError('');
					if (res?.data?.getAddresses?.length > 0) {
						setSuggestions(res?.data?.getAddresses);
						setLoading(false);
					} else {
						setSuggestions([]);
						setError('address not found');
						setLoading(false);
					}
				} catch (e) {
					console.error(e?.message);
					setSuggestions([]);
					setError(
						e?.message?.includes('No addresses found with keyword')
							? 'address not found'
							: e?.message
					);
					setLoading(false);
				} finally {
					_setLoading(false);
					onSearchDone && onSearchDone({ value, suggestions });
				}
			}, 1000),
		[] // eslint-disable-line react-hooks/exhaustive-deps
	);

	const onChangeHandler = e => {
		setLoading(true);
		_setLoading(!!e.target.value);
		setValue(e.target.value);
		onChange(e.target.value);
		debouncedSearch(e.target.value);
	};

	return (
		<AddressInput ref={divRef}>
			<InputField
				type="search"
				ref={inputRef}
				id="fiber-delivery-address-input"
				data-cy="fiber-delivery-address-input"
				placeholder="Skriv inn din adresse"
				value={value}
				loading={loading || _loading}
				icon={!value && faSearch}
				clear={
					(!loading || !_loading) &&
					value && (
						<SearchClear
							onClick={reset}
							title="Klikk for å tømme søket">
							<FontAwesomeIcon icon={faCircleXmark} />
						</SearchClear>
					)
				}
				onChange={onChangeHandler}
				onKeyDown={async e => {
					if (e.key === 'Enter') {
						e.preventDefault();

						if (!suggestions?.length > 0) return;

						onSelect(suggestions[0]);
						setValue(suggestions[0].result);
						onChange(suggestions[0].result);
						setSuggestions([]);
					}
				}}
				{...inputProps}
			/>

			{suggestions?.length > 0 && (
				<Dropdown data-cy="fiber-addresses-list">
					{suggestions.map(suggestion => (
						<li key={suggestion.result}>
							<BlankButton
								onClick={e => {
									e.preventDefault();

									onSelect(suggestion);
									setValue(suggestion.result);
									onChange(suggestion.result);
									setSuggestions([]);
								}}
								title={suggestion.result}>
								{suggestion.result}
							</BlankButton>
						</li>
					))}
				</Dropdown>
			)}
		</AddressInput>
	);
});
